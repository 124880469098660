var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid explorer"},[_c('div',{staticClass:"page-title"},[_c('i',{staticClass:"fa-regular fa-files"}),_c('h1',[_vm._v(_vm._s(_vm.$t("COMMON.FILES")))])]),_c('div',[_c('template',{slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("FILES.FILES_LIST")))])]),_c('div',{staticClass:"col-6 text-right"})])]),_c('file-list-table',{key:_vm.renderKey,on:{"onEditFile":_vm.openEditFileModal,"onDeleteFile":_vm.deleteFile,"onViewFile":_vm.openViewFileModal}})],2),(_vm.file)?_c('file-edit-form-modal',{attrs:{"showModal":_vm.showEditFormModal,"fileData":_vm.file},on:{"onCloseModal":_vm.closeModal}}):_vm._e(),(_vm.file)?_c('file-view-modal',{attrs:{"showModal":_vm.showViewModal,"fileData":_vm.file},on:{"onCloseModal":_vm.closeModal,"fileUpdated":_vm.refreshFile,"editFile":(id) => {
        _vm.closeModal(false);
        _vm.openEditFileModal(id);
      },"deleteFile":(id) => {
        _vm.closeModal(false);
        _vm.deleteFile(id);
      }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }